<script>
import {AddressEditor} from '@/components/shared'
export default {
  name: 'EditForm',
  props: ['type'],
  components: {
    AddressEditor
  },
  data() {
    return {
      model: {
        name: null,
        address: null,
        addressType: 'CUSTOMER',
        comment: null,
        destination: true,
        lat: null,
        lng: null,
        memberShipCode: null,
        phone: null,
        plate: null,
        unit: null,
        userId: null
      }
    }
  }
}
</script>
