<template>
  <m-card :title="$t('shared.add')">
    <address-editor ref="address" v-model="model" show-code />

    <template #actions>
      <page-footer @submit="submit" :loading="progressing" />
    </template>
  </m-card>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Create',
  mixins: [EditForm],
  methods: {
    submit() {
      this.$preSubmit('address').then((proceed) => {
        if (proceed) {
          this.$api.addresses
            .create(this.model)
            .then((res) => {
              this.model = res
              this.progressing = false
              this.$showSuccess(this.$t('addresses.addressAdded'))
            })
            .catch((err) => {
              this.progressing = false
              this.$showError(err)
            })
        }
      })
    }
  }
}
</script>
